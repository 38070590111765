

function Download({ qrImage, buttonUrl }){


    return (<div className="shadow self-center mt-10 p-6 rounded content-center flex space-x-4 flex-row">
        <div>
            <img src={qrImage} alt="" className="w-40 h-40" />
            <p className="text-base text-center text-gray-400 font-bold">Scan to download</p>
        </div>
        <div className="w-min flex flex-col items-center">
            <div className="w-0.5 bg-gray-400 rounded-lg h-full"/>
            <h4 className="text-sm text-gray-500 font-bold">OR</h4>
            <div className="w-0.5 bg-gray-400 rounded-lg h-full" />
        </div>
        <div className="flex items-center">
            <a href={buttonUrl} target="_blank" rel="noopener noreferrer" className="border border-none">
                <img src="https://www.niftybuttons.com/googleplay/googleplay-button8.png" alt=""/>
            </a>
        </div>
    </div>);
}

export default Download;
export const studentImages = [
    "https://firebasestorage.googleapis.com/v0/b/kabbacomeducation.appspot.com/o/extra%2Fstudent%2FScreenshot_2021-03-11-22-05-32-629_com.kabbacom.students.jpg?alt=media&token=65b7079b-d134-4b1f-8afc-c7f67c3c2556",
    "https://firebasestorage.googleapis.com/v0/b/kabbacomeducation.appspot.com/o/extra%2Fstudent%2FScreenshot_2021-03-11-22-05-38-949_com.kabbacom.students.jpg?alt=media&token=65b7079b-d134-4b1f-8afc-c7f67c3c2556",
    "https://firebasestorage.googleapis.com/v0/b/kabbacomeducation.appspot.com/o/extra%2Fstudent%2FScreenshot_2021-03-11-22-05-44-167_com.kabbacom.students.jpg?alt=media&token=65b7079b-d134-4b1f-8afc-c7f67c3c2556",
    "https://firebasestorage.googleapis.com/v0/b/kabbacomeducation.appspot.com/o/extra%2Fstudent%2FScreenshot_2021-03-11-22-06-03-797_com.kabbacom.students.jpg?alt=media&token=65b7079b-d134-4b1f-8afc-c7f67c3c2556",
    "https://firebasestorage.googleapis.com/v0/b/kabbacomeducation.appspot.com/o/extra%2Fstudent%2FScreenshot_2021-03-11-22-06-15-395_com.kabbacom.students.jpg?alt=media&token=65b7079b-d134-4b1f-8afc-c7f67c3c2556"
];

export const teacherImages = [
    "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/teacher/Screenshot_2021-03-11-22-14-15-146_com.kabbacom.teachers.jpg",
    "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/teacher/Screenshot_2021-03-11-22-14-22-715_com.kabbacom.teachers.jpg",
    "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/teacher/Screenshot_2021-03-11-22-14-30-006_com.kabbacom.teachers.jpg",
    "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/teacher/Screenshot_2021-03-11-22-14-44-623_com.kabbacom.teachers.jpg",
    "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/teacher/Screenshot_2021-03-11-22-15-33-266_com.kabbacom.teachers.jpg",
    "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/teacher/Screenshot_2021-03-11-22-15-42-930_com.kabbacom.teachers.jpg"
];

export const icons = {
    teachers: "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/icons/teachers.png",
    students: "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/icons/students.png"
};

export const qrImage = {
    teachers: "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/qr%20codes/teachers.png",
    students: "https://storage.cloud.google.com/kabbacomeducation.appspot.com/extra/qr%20codes/students.png"
};

export const appUrl = {
    teachers: "https://play.google.com/store/apps/details?id=com.kabbacom.teachers",
    students: "https://play.google.com/store/apps/details?id=com.kabbacom.students"
};

export const data = {
    students: `This is the KabbaCom Education Students app. We have put in place a process that vets all teachers on this platform, who are regulated by government authorities and therefore have valid teaching permits issued by governments.
                KabbaCom Education Students application is a simple straight to the point, but yet intuitive. The app aids your child to study with only the best teachers across the globe depending on the country curriculum.
                Find the Super teachers for any subject you wish your child to excel in and let your child learn from the comfort of your home at his or her pace. The student can interact with his or her teacher on different topics of a subject. 
                The app is designed in such a manner to allow the child to learn in the country curriculum.
                KabbaCom Education Students app allows you the parent track the content your child has covered.
                Kabba Communications is a fully registered and regulated company. As a company,  we strive to bring you the best communication solutions to enhance your lively hood.
                This is not just a simple home school app. it is a window to the best teachers
                in the world and the country you are best. it is a power house of knowledge in the palm of your hand.
                All content provided to your child from the teachers is vetted by is vetted by the various country regulatory authorities. This to ensure that your child gets quality education from super teachers to help them excel in the subjects of their choice`,

    teachers: `This is the kabbacom education teachers dashboard app. 
            This applications allows you the teacher, teacher intructor or tutor. The teacher can create an account by signing up on the application. There after your account is verified and you then have the ability to upload lessons and teach your students, 
            teachers can also have real time disccutions with their students through messages.`,

    about: `We, Kabba Communications (U) Ltd are a Ugandan company aimed at changing the technology industry of Africa as a content and the world at large 
            though developing software, hardware and strategies to fight Africa's problems in different sectors like education, farming, health, economic, etc.
            our current innovation is the KabbaCom education mobile applications and website aimed at online and distance learning read below for more information.`

};
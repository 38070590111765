

function Logo() {

    return (
        <h1 className="font-bold shadow p-2 bg-blue-700 rounded">
            <span className="text-l1">K</span>
            <span className="text-l2">a</span>
            <span className="text-l3">b</span>
            <span className="text-l4">b</span>
            <span className="text-l5">a</span>
            <span className="text-l6">C</span>
            <span className="text-l7">o</span>
            <span className="text-l8">m</span>
        </h1>
    );
}

export default Logo;

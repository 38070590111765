

function Footer() {

    return (<footer className="flex flex-col mt-10 w-full h-20 white">
        <hr className="bg-orange ml-5 mr-5"/>

        <div className="flex flex-col items-center">
            <h1 className="text-xl font-bold text-gray-700">You can contact us using our email</h1>
            <h1 classname="text-lg mt-3 font-bold text-gray-600">kabbacommunications@gmail.com</h1>

            <h1 className="text-sm bottom-0 mt-10 mb-3 font-bold text-gray-500">All rights recieved | This is a property of Kabba Communcations (U) Ltd</h1>
        </div>

    </footer>);
}

export default Footer;
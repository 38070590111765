import Logo from "../logo/Logo";
import Footer from "../footer/Footer";
import Slide from "../slide/Slide";
import Download from "../download/Download";
import { studentImages, teacherImages, icons, qrImage, data, appUrl } from "../../data/data";


function Home() {


    return (<div className="flex flex-col h-full space-y-10 w-full">
        <div className="flex flex-col w-full h-80 shadow items-start place-content-center bg-orange">
            <div className="text-xl md:text-2xl ml-4 mr-4 mt-4">
                <Logo />
            </div>

            <div className="text-3xl md:text-5xl ml-4 mr-4 mt-4">
                <h3 className="text-white font-bold">KabbaCom Education</h3>
            </div>
        </div>

        <div className="shadow rounded p-5 m-5">
            <h1 className="capitalize font-bold text-2xl md:text-3xl text-darkOrange">About</h1>
            <hr />
            <p className="font-bold text-lg md:text-xl pt-4 text-gray-500">{data.about}</p>
        </div>

        {/*<h1 className="pl-5 font-bold text-3xl text-black shadow">These are some of our products</h1> */}

        <div className="flex flex-col">
            <div className="flex flex-row p-5">
                <img src={icons.students} alt="" className="w-20 h-20" />
                <h1 className="pl-4 text-2xl self-end md:text-3xl font-bold text-darkOrange">KabbaCom Education Students</h1>
            </div>
            <hr />

            <Slide data={studentImages} />

            <Download qrImage={qrImage.students} buttonUrl={appUrl.students} />

            <div className="px-5">
                <h1 className="text-2xl pt-8 md:text-3xl font-bold text-black">Description</h1>
                <hr />
            </div>
            
            <div className="shadow rounded p-10 m-5 bg-darkOrange">
                <p className="text-white font-bold text-lg md:text-xl">{data.students}</p>
            </div>
        </div>

        <div className="flex flex-col">
            <div className="flex flex-row p-5">
                <img src={icons.teachers} alt="" className="w-20 h-20" />
                <h1 className="pl-4 text-2xl self-end md:text-3xl font-bold text-darkOrange">KabbaCom Education Super Teachers</h1>
            </div>
            <hr />

            <Slide data={teacherImages} />

            <Download qrImage={qrImage.teachers} buttonUrl={appUrl.teachers} />

            <div className="px-5">
                <h1 className="text-2xl pt-8 md:text-3xl font-bold text-black">Description</h1>
                <hr />
            </div>

            <div className="shadow rounded p-10 m-5 bg-darkOrange">
                <p className="text-white font-bold text-lg md:text-xl">{data.teachers}</p>
            </div>
        </div>

        <div className="relative bottom-0 inset-x-0">
            <Footer />
        </div>
    </div>);
}

export default Home;
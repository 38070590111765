import React from "react";
import Slider from "react-slick";

function Slide({ data }) {

    var settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        centerMode: true,
        speed: 500,
        slidesToShow: 4,
        swipeToSlide: true,
        variableWidth: true
    };

    return (<div className="mt-5 py-10 overflow-hidden shadow">
        <Slider {...settings}>
            {data.map(element => {
                return (
                    <div key={element} className="w-20">
                        <img className="h-80 object-fill" src={element} alt=""/>
                    </div>);
            })}
        </Slider>
    </div>);
}

export default Slide;